@import url('https://fonts.googleapis.com/css2?family=Gabarito:wght@400;500&display=swap');

* {
    padding: 0;
    margin: 0;
    font-family: 'Gabarito', sans-serif;

}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

a,
span,
strong {
    display: inline-block;
}

a {
    text-decoration: none;
}

input.form-control:focus {
    box-shadow: none;
}

.sectionTitle {
    margin-bottom: 40px;
}

.sectionTitle h6 {
    color: #0d6efd;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #021F2C;
    font-weight: 500;
}

p {
    color: rgb(0 0 0 / 65%);
    margin-bottom: 6px;
}

.sectionPD {
    padding: 60px 0;
}

.bgGray {
    background-color: #f7f7f7;
}

.coverBg {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

}

/* 1. Header */
/* 2. Footer */
/* 3. Home */
/* 4. Login */



/* Common start */
.backTop {
    position: fixed;
    right: 30px;
    bottom: 30px;
    background-color: #0d6efd;
    width: 40px;
    height: 40px;
    font-size: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #ffff;
    transition: .4s ease-in-out;
    cursor: pointer;
}

.breadCrumbSec {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 350px;
}

.breadCrumbSec::before {
    content: "";
    background: #021F2C;
    opacity: 0.88;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.breadCrumbInfo {
    position: absolute;
    bottom: 30px;
    width: 50%;
}

.breadCrumbInfo h3 {
    color: #fff;
    text-transform: capitalize;
}

.breadCrumbInfo p {
    color: #f1f1f1;
}


.feedbackInfo {
    position: fixed;
    right: -301px;
    top: 20%;
    display: flex;
    align-items: start;
    transition: all ease .2s;
}

.feedbackInfo.active {
    right: 0;
}

.feedbackArea {
    width: 300px;
    background: #f7f7f7;
    padding: 30px;
    position: relative;
}

.headerFeed {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 25px;
    cursor: pointer;
}

.feedbackBtn {
    background: #57bb4a;
    padding: 7px;
    color: #fff;
    transform: rotate(270deg);
    position: absolute;
    left: -60px;
    top: 21px;
    cursor: pointer;
}

.headerFeed {
    position: absolute;
    right: 10px;
    top: 5px;
    font-size: 25px;
}

.feedbackArea .rating i {
    font-size: 23px;
    padding: 0 5px;
    cursor: pointer;
}

.feedbackArea .rating {
    margin-bottom: 20px;
}

/* Common end */
/* Header start */
.navHeader {
    position: fixed;
    width: 100%;
    top: 10px;
    z-index: 11;
    padding: 0;
    transition: .4s ease-in-out;
}

.header.active .navHeader {
    top: 0;
    background-color: #fff;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.navHeader .navHeaderContain {
    background: #fff;
    padding: 0 0 0 15px;
}

.brand img {
    width: 150px;
}

.menuList .nav-item .nav-link {
    padding: 20px 10px;
}

.addProp button {
    background: #57bb4a;
    padding: 20px;
    color: #fff;
    border: 0;
}

.bookRideModal {
    max-width: 1000px;
    width: 100%;
}

.bookRideModal .modal-content {
    border: 0;
    border-radius: 0;
}

.brand h3 span {
    text-transform: uppercase;
    color: #57bb4a;
}

.brand h3 {
    margin: 0;
}

/* Header end */

/* Footer start */


.footer {
    background: #021F2C;
    margin-top: 60px;
}

.footerBox {
    position: relative;
    z-index: 1;
    padding: 0 7px;
}

.footerBox:before {
    content: "";
    width: 290px;
    height: 440px;
    background: #57bb4a;
    position: absolute;
    left: -12px;
    top: -110px;
    z-index: -1;
}

.footerCategory h4 {
    color: #fff;
}

.footerCategory p {
    color: #bbb;
}

.footerCategory ul li a {
    color: #fff;
    padding: 5px 0;
}

.footerCategory>a {
    color: #57bb4a;
}

.footerCategory h4 {
    color: #fff;
    margin: 0;
}

.footerCategory .line {
    width: 50px;
    height: 5px;
    background: #57bb4a;
    border-radius: 30px;
    margin: 10px 0 20px;
}

.footerCategory p {
    color: #bbb;
}

.footerCategory ul li a {
    color: #fff;
    padding: 5px 0;
}

.footerCategory>a {
    color: #57bb4a;
}

.footerBox h3 {
    color: #fff;
}

.footerBox form.newsLetter {
    margin: 20px 0 10px;
}

.footerBox form.newsLetter input {
    border-radius: 0;
    height: 50px;
    margin-bottom: 15px;
}

.footerBox form.newsLetter label {
    color: #fff;
}

.footerBox .icon i {
    color: #fff;
    font-size: 50px;
}

.footerBox form.newsLetter .btn.btn-primary {
    background: #021F2C;
    width: 100%;
    border: 0;
    border-radius: 0;

}

.footerCategory .number {
    text-align: center;
    font-size: 30px;
    margin-top: 20px;
}

.footerCategory .line {
    width: 50px;
    height: 5px;
    background: #57bb4a;
    border-radius: 30px;
    margin: 10px 0 20px;
}

.copyRightSec {
    margin-top: 90px;
    padding: 20px;
    background: #02141c;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.copyRightSec .list ul {
    display: flex;
    column-gap: 20px;
    margin-bottom: 10px;
}

.copyRightSec .list ul li a {
    color: #bbb;
}

.copyRightSec>p {
    color: #ddd;
}

.copyRightSec .list p {
    color: #bbb;
}

.copyRightSec .social-style1 a {
    width: 40px;
    height: 40px;
    background: #021f2c;
    color: #fff;
    text-align: center;
    line-height: 40px;
}

.copyRightSec .social-style1 {
    display: flex;
    column-gap: 2px;
}

.copyRightSec .list ul li {
    position: relative;
}

.copyRightSec .list ul li:after {
    content: "";
    position: absolute;
    width: 1px;
    height: 100%;
    background: #bbb;
    right: -10px;
}

.copyRightSec .list ul li:last-child:after {
    display: none;
}

.footerCategory .subTitle h6 {
    color: #fff;
    text-transform: uppercase;
    font-weight: normal;
}

/* Footer end */

/* Home start */
.banner {
    position: relative;
    height: 650px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
}

.banner:before {
    content: "";
    background: linear-gradient(to right, #000000, #ffffff00);
    opacity: 0.58;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.bannerContent {
    position: relative;
    margin-top: 170px;
}

.bannerContent h6 {
    color: #fff;
}

.bannerContent h1 {
    font-weight: bold;
    font-size: 60px;
    color: #fff;
    margin: 20px 0;
}

.bannerContent p {
    color: #fff;
    margin-bottom: 30px;
}

.readMore {
    background-color: #57bb4a;
    text-transform: uppercase;
    color: rgb(255, 255, 255);
    font-size: 15px;
    padding: 21px 50px;
    border: 0;
}

.bannerImg {
    text-align: right;
    margin: 90px 0 0 0;
}

.bannerImg img {
    width: 60%;

}

.bannerImg {
    text-align: right;
    position: relative;
    height: 100%;
    width: 100%;
}

.circleText {
    width: 120px;
    height: 120px;
    background: #0177ff;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 30%;
    right: 40%;
}

.circleText span {
    font-size: 34px;
    line-height: 34px;
    color: #fff;
}

.circleText p {
    margin: 0;
    text-transform: uppercase;
    color: #fff;
}

.aboutContent h6 {
    color: #60626d;
    font-weight: normal;
}

.aboutContent h2 {
    margin: 24px 0;
}

.aboutContent p {

    color: #60626d;
    font-weight: normal;

}

.aboutContent ul {
    list-style: auto;
    margin: 20px 20px;
}

.aboutContent ul li {
    line-height: 34px;
}

.aboutImg img {
    width: 90%;
}

.aboutImg {
    position: relative;
}

.aboutImg .text {
    position: absolute;
    bottom: -100px;
    width: 80%;
    background: #fff;
    padding: 20px;
}

.ExperienceText ul li {
    margin-bottom: 14px;
}

.ExperienceText ul li .nameInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.ExperienceText ul li .barInfo .progress {
    height: 7px;
}

.ExperienceText ul li .barInfo .progress .progress-bar {
    color: #0177ff;
}

.expFigure {
    position: relative;
    text-align: right;
}

.expFigure img {
    width: 80%;
}

.expFigure .text {
    position: absolute;
    bottom: -80px;
    width: 250px;
    text-align: left;
    background: #0d6efd;
    padding: 20px;
    right: 0;
}

.expFigure .text p,
.expFigure .text h5 {
    color: #fff;
}

.collectionCard {
    border: 1px solid #f7f7f7;
    padding: 20px;
    margin-bottom: 24px;
}

.collectionCard .text ul li {
    color: rgb(0 0 0 / 65%);
    font-size: 15px;
    line-height: 30px;
}

.collectionCard .text ul {
    margin: 20px 0;
}

.collectionCard .text>a {
    color: #0077ff;
}

.availSec,
.offerBox {
    position: relative;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top;
}

.availImg {
    width: 50%;
    margin: 0 auto;
    position: relative;
}

.availImg .text {
    width: 150px;
    height: 150px;
    background: #0177ff;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 40%;
    left: 50%;
    text-align: center;
    padding: 20px;
}

.availImg .text h5,
.availImg .text p {
    color: #fff;

}

.availContent h6 {
    color: #bbb;
}

.availContent h2 {
    color: #fff;
    font-size: 50px;
    margin: 20px 0;
}

.availContent p {
    color: #bbb;
}

.availContent h6 {
    color: #bbb;
}

.availContent h2 {
    color: #fff;
    font-size: 50px;
    margin: 20px 0;
}

.availContent p {
    color: #bbb;
}

.downloadSec {
    display: flex;
    column-gap: 10px;
    margin-top: 40px;
}

.downloadSec img {
    width: 145px;
    height: 45px;
}

.availContent h6 {
    color: #bbb;
}

.availContent h2 {
    color: #fff;
    font-size: 50px;
    margin: 20px 0;
}

.availContent p {
    color: #bbb;
}

.downloadSec {
    display: flex;
    column-gap: 10px;
    margin-top: 40px;
}

.downloadSec img {
    width: 145px;
    height: 45px;
}

.reviewCard {
    background: #f7f7f7;
    padding: 20px;
    margin-bottom: 24px;
}

.reviewCard .left {
    display: flex;
    align-items: center;
    column-gap: 20px;
}

.reviewCard .left image {
    width: 80px;
}

.reviewCard .left .image img {
    width: 70px;
    height: 70px;
    border-radius: 50%;
}

.reviewCard .left .text h5 {
    margin: 0;
    font-size: 17px;
}

.reviewCard .left .text p {
    font-size: 14px;
}

.reviewCard .author {
    margin-top: 30px;
}

.reviewCard .para p {
    font-size: 15px;
}

.blogCard {
    display: flex;
    column-gap: 20px;
    margin-bottom: 30px;
    padding: 0 20px;
}

.blogCard .blogImg,
.blogCard .blogContent {
    width: 50%;
}

.blogCard .blogImg img {
    border-radius: 10px;
}

.blogContent h5 {
    font-size: 17px;
    margin-bottom: 0;
}

.blogContent p {
    font-size: 14px;
}

.blogContent .time {
    font-size: 14px;
    margin-bottom: 10px;
}

.galleryCard .galleryImg img {
    border-radius: 10px;
    transition: .4s ease-in-out;
}

.galleryCard .galleryImg {
    margin-bottom: 24px;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: .4s ease-in-out;
    border-radius: 10px;

}

/* .galleryCard .galleryImg:before {
    content: "";
    background: #021f2cad;
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    border-radius: 10px;
    transition: .4s ease-in-out;
    opacity: 0;
} */

.galleryCard .galleryImg:hover img {
    transform: scale(1.1) rotate(-1deg);
}

.galleryCard .galleryImg:hover:before {
    opacity: 1;
    z-index: 1;
}

.VehicleImg {
    text-align: center;
}

.VehicleImg img {
    width: 70%;
    margin: auto;
}

.vehicleBox,
.colorOption {
    padding: 25px;
    background: #f7f7f7;
    margin-bottom: 24px;
}

.elementIconList li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    line-height: 32px;
}

.elementIconList {
    margin-top: 20px;
}

.offerBox {
    padding: 35px 25px;
    text-align: center;
    color: #fff;
}

.offerBox h5 {
    color: #fff;
}

.offerBox p {
    color: #b5b5b5;
    margin: 0;
}

.offerBox .text {
    font-size: 40px;
    color: #57bb4a;
    margin-bottom: 20px;
}

.offerInner {

    border: 1px solid #bbb;
    padding: 25px;
}

.addInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 0;
    border-bottom: 1px solid #bbb;
    margin-bottom: 30px;
}

.addInfo .name h4 {
    margin: 0;
}

.addInfo .price {
    display: flex;
    align-items: end;
}

.addInfo .price span {
    font-size: 40px;
    color: #0d6efd;
}

.addInfo .price a {
    background: #fff;
    border: 1px solid #0d6efd;
    padding: 4px 10px;
    color: #0d6efd;
    transition: .4s ease-in-out;
}

.addInfo .price a:hover {
    background: #0d6efd;
    border: 1px solid #0d6efd;
    padding: 4px 10px;
    color: #fff;
}


.positionSticky {
    position: sticky;
    top: 100px;
}

.vehicleTab .nav-tabs .nav-item .nav-link.active {
    background: #f7f7f7;
    border: 0;
    border-bottom: 2px solid #0d6efd;
    color: #0d6efd;
    border-radius: 0;
}

.vehicleTab .nav-tabs .nav-item .nav-link {
    color: #021F2C;
    border: 0;
    border-radius: 0;
    padding: 8px 10px;
}

.listInfo li h6 {
    margin: 0;
    color: #959595;
    font-weight: normal;
}

.listInfo li p {
    font-size: 20px;
}

.innerTabCard {
    padding: 20px;
    background: #f7f7f7;
}

.innerTabCard ul {
    list-style: circle;
    margin-left: 20px;
}

.innerTabCard .item p {
    padding-left: 15px;
}

.innerTabCard .spec ul li strong {
    font-weight: 400;
}

.innerTabCard ul li .inner {
    list-style-type: number;
}

.colorOption {
    display: flex;
    justify-content: space-between;
}

.colorOption ul {
    display: flex;
    column-gap: 8px;
}

.colorOption ul li .color {
    width: 30px;
    height: 40px;
    background: #e93e00;
    cursor: alias;
}

.colorOption ul li span {
    font-size: 12px;
    text-transform: uppercase;
}

.colorOption ul li {
    width: 40px;
}

.colorOption ul li.green .color {
    background: #57bb4a;
}

.colorOption ul li.red .color {
    background: #e93e00;
}

.colorOption ul li.blue .color {
    background: #0d6efd;
}

.bookFormBox {
    margin-top: 50px;
}

.bookForm input,
.bookForm select {
    border-radius: 0;
    height: 45px;
    font-size: 14px;
}

.bookForm textarea {
    border-radius: 0;
}

.bookForm .readMore {
    border: 0;
    width: 100%;
    padding: 11px 50px;
}

.stepCard {
    text-align: center;
    padding: 20px;
}

.stepCard .icon {
    width: 100px;
    height: 100px;
    background: #57bb4a;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid #e0ffdc;
    color: #fff;
    font-size: 40px;
}

.stepCard {
    text-align: center;
    padding: 20px;
}

.stepCard .icon {
    width: 100px;
    height: 100px;
    background: #57bb4a;
    margin: 0 auto 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: 5px solid #e0ffdc;
    color: #fff;
}

.contactDetail {
    background: #fff;
    padding: 50px 30px;
}

.contactMap {
    position: relative;
}

.contactDetail h4 {
    margin-bottom: 20px;
}


.contactDetail .item {
    margin-bottom: 25px;
}

.contactDetail .item p.call,
.contactDetail .item p.mail {
    color: #0d6efd;
    font-size: 20px;
}

.categoryItem a {
    position: relative;
    transition: .4s ease-in-out;
    transform: translateY(0);

}

.categoryItem a:hover {
    transform: translateY(-10px);
}

.categoryItem a h6 {
    position: absolute;
    bottom: -10px;
    background: #f7f7f7;
    padding: 7px 20px;
    text-align: center;
    left: 0;
    width: 100%;
}

.brandsItem ul {
    align-items: center;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.brandsItem ul li {
    width: 15%;
}

.brandsItem ul li a {
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 8px 0 rgba(7, 7, 7, .1);
    display: flex;
    height: 110px;
    justify-content: center;
    padding: 35px;
    text-align: center;
}

.accessList ul {
    display: flex;
    column-gap: 30px;
    margin-top: 30px;
}

.accessList ul li {
    padding: 20px;
    border: 1px solid #bbb;
    width: 150px;
    height: 150px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.accessList ul li img {
    width: 40%;
    filter: invert(1);
}

.accessList ul li h6 {
    margin: 10px 0 0;
    color: #fff;
}


.mediaList ul {
    align-items: center;
    -webkit-column-gap: 10px;
    column-gap: 10px;
    display: flex;
    flex-wrap: wrap;
}

.mediaList ul li {
    width: 15%;
}

.mediaList.mediaList1 ul li a {
    border: 0;
}

.mediaList ul li a {
    background: #fff;
    border-radius: 8px;
    border: 1px solid #f9f9f9;
    display: flex;
    height: 110px;
    justify-content: center;
    padding: 35px;
    text-align: center;
}

.teamCard {
    background: #fff;
    padding: 30px;
    text-align: center;
    margin-bottom: 24px;
}

.teamCard .header .image img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}

.teamCard .header .text {
    margin: 10px 0;
}

.teamCard .header .text h5 {
    margin: 0;
}

.teamCard .header {
    border-bottom: 1px solid #f7f7f7;
}

.teamCard .social-style1 a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #021f2c;
}

.brandsItem ul.viewAllClient {
    justify-content: space-between;
}

.brandsItem ul.viewAllClient li {
    margin-bottom: 24px;
}


.colorDegreeName ul li .color {
    width: 30px;
    height: 35px;
    background: #e93e00;
}

.colorDegreeName ul li.red .color {
    background-color: #e93e00;
}

.colorDegreeName ul li.blue .color {
    background-color: #0d6efd;
}

.colorDegreeName ul li.green .color {
    background-color: #57bb4a;
}

.colorDegreeName ul li.golden .color {
    background-color: gold;
}

.colorDegreeName ul li.white .color {
    background-color: #fff;
}

.colorDegreeName ul li.black .color {
    background-color: #000;
}

.colorDegreeName ul li {
    display: flex;
    column-gap: 8px;
    align-items: center;
    margin-bottom: 10px;
}

.colorDegreeName ul li .name {
    text-transform: capitalize;
}

.colorDegreeName h4 {
    margin-bottom: 20px;
}

.colorDegreeSide ul {
    list-style: circle;
}

.colorDegreeSide ul li {
    font-size: 20px;
    margin-bottom: 30px;
}

.rotateImage>div {
    margin: 0 auto;
    width: 80%;
}

.ourTrusted ul {
    display: flex;
    justify-content: space-between;
    column-gap: 10px;
}

.ourTrusted ul li {
    padding: 10px;
    width: 12%;
    overflow: hidden;
}

.ourTrusted ul li a img {
    transition: all ease .3s;
}

.ourTrusted ul li a:hover img {
    transform: scale(1.1);
    filter: opacity(.5);
}

.reasonCard {
    text-align: center;
    padding: 30px;
    border: 1px solid #f7f7f7;
    margin-bottom: 24px;
    min-height: 160px;
}

.reasonCard .icon {
    font-size: 30px;
}

.ourTrusted h3 {
    background: #f7f7f7;
    padding: 10px;
    font-size: 20px;
}

.newsItem a {
    padding: 0 12px;
}

.dealerTestItem {
    padding: 30px;
    background: #f7f7f7;
    width: 95% !important;
    margin-bottom: 20px;
}

/* Home end */

/* Login start */
body.loginPage header,
body.loginPage footer {
    display: none;
}

.loginSec {
    height: 100%;
}

.loginInfo {
    width: 400px;

}

.loginInfo .title {
    margin-bottom: 30px;
}

.loginForm {
    background: #fff;
    padding: 30px;
}

.loginForm input {
    height: 45px;
    border-radius: 0;
    font-size: 14px;
}

.loginForm .btn.btn-primary {
    background: #57bb4a;
    border-radius: 0;
    padding: 6px 30px;
    border-color: #57bb4a;
}

/* Login end */